@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.container{
max-width: 1260px; 
  margin: 0 auto; 
  padding: 0 15px; 
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the default number input spinner in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

 