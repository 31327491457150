


/* .container {
    max-width: 1460px;
    margin: 0 auto;
} */

.bg-custom {
  background-color: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(5px); */
   -webkit-backdrop-filter: blur(5px); 
}

.swiper-horizontal > .swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 132px !important;
}


.sticky-header {
    position: sticky;
    top: 0;
    background-color: rgba(7, 16, 19, 0.1); 
    width: 100%;
    z-index: 1000; 
    backdrop-filter: blur(32px); 
    -webkit-backdrop-filter: blur(32px); 
    /* padding: 10px;  */
}
@media only screen and (max-width: 640px) {
    .sticky-header {
        position: fixed; 
        bottom: 0; 
        top: auto; 
        background-color: rgba(7, 16, 19, 0.1); 
        width: 100%;
        z-index: 1000; 
        backdrop-filter: blur(32px); 
        -webkit-backdrop-filter: blur(32px); 
        /* padding: 10px;  */
    }
}


.sticky_header_two {
    background-color: rgba(7, 16, 19, 0.1); 
    z-index: 1000 !important; 
    margin-top: -100px !important;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    
}


button, input {
    z-index: 100; 
    background-color: rgba(255, 255, 255, 0.9); 
}


.sticky-header * , .sticky_header_two * {
   
    opacity: 1; 
} 







